<template>
  <v-row>
    <v-col cols="12" md="6">
      <search-box 
          label="* Contrato"
          popup-label="Selecione um Contrato"
          :id.sync="value.idContrato"
          :descricaoCompleta.sync="value.descricaoContrato"
          :hidden-clear="true"
          :value="contrato"
          :loader-fn="loaderContrato"
          item-key="id"
          item-text="descricaoCompleta"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Contrato encontrada
          </p>
        </template>
      </search-box>
    </v-col> 
    <v-col cols="12" sm="3">
        <v-text-field dense
                  label="* Número do Processo"
                  :rules="[
                    this.$validators.string.required,
                  ]"
                  v-model="value.numProcesso" type="text"/>
    </v-col>  
    <v-col cols="12" md="3">
        <v-text-field label="* Mês/Ano"
                      dense
                      type="month"
                      v-model="value.mesRef"
                      :rules="[
                          this.$validators.string.required,
                    ]"/>
    </v-col>    
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12">
          <strong>Informe as notas </strong> 
          <v-btn small color="secondary" @click="addNotas()">
            <v-icon>mdi-playlist-plus</v-icon>Add
          </v-btn>
        </v-col>
      </v-row>
      <v-divider/>
    </v-col>
    <v-col cols="12">
      <v-row v-for="(nota, index) in value.notas" :key="index">    
        <v-col cols="12" sm="2">
            <v-text-field label="* Número"
                          dense
                          type="text"
                          v-model="nota.numNota"
                          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field dense
                    label="Descrição"
                    v-model="nota.descricao" type="text"/>
        </v-col>
        <v-col cols="12" sm="3">
            <v-text-field label="* Data Vencimento"
                          dense
                          type="date"
                          v-model="nota.dataVencimento"
                          />
        </v-col>  
        <v-col cols="12" sm="2">
          <v-text-field dense
              label="* Valor"
              prefix="R$"
              v-model="nota.valor" type="number"/>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn small color="error" @click="removeNota()">
            <v-icon>mdi-playlist-remove</v-icon>
          </v-btn>                         
        </v-col>                           
      </v-row>
    </v-col>        
    <v-col cols="12" md="12">
      <strong>Valor Total (R$):</strong> {{value.valorTotal}}
    </v-col>  
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
//PKs
import {findAll as findAllContrato} from "../../../api/contrato";
import Vue from 'vue';

export default {
  name: "ContratadoForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      error: null,
    }
  },
  computed: {
    contrato(){
      if (this.value.idContrato == null) return null;
      return {
        id: this.value.idContrato,
        descricaoCompleta: this.value.descricaoContrato
      }      
    },    
  },
  watch: {
    'value.notas': {
      deep: true,
      handler(newValue) {
        this.value.valorTotal = (newValue || []).reduce((acumulador, nota) => parseFloat(acumulador) + parseFloat(nota.valor), 0);
      }
    },
    
  },  
  methods: {
    loaderContrato(page, search) {
      return findAllContrato(page, {
        nome: search
      })
    },  
    addNotas() {
      this.value.notas.push({
        id: "",
        idPagamento: "",
        numNota: "",
        descricao: "",
        dataVencimento: "",
        valor: 0,		

      });
    },
    removeNota(index) {
      this.value.notas.splice(index, 1);
    }
  },    
  mounted() {
  }
}
</script>

<style scoped>

</style>