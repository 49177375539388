<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="1200px"
            :style="{ 'min-height': '400px' }"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        {{ getTitle }}
        <v-spacer/>
        <v-btn icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">

        <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
          <PagamentoForm v-model="item"/>
        </v-form>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PagamentoForm from "../forms/PagamentoForm";
import {create, update} from "../../../api/pagamento";
import {ApiError} from "../../../api/core";

export default {
  name: "CreateEditPagamentoDialog",
  components: {PagamentoForm},
  props: ['value', 'item'],
  data() {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return this.item?.id ? 'Editar Pagamento' : 'Adicionar Pagamento'
    },
    notasPagamentoSelecionadas() {
      return (this.item.notas || []).map(
        c => `${c.id}|${c.numNota}|${c.descricao}|${c.dataVencimento}|${c.valor}`).join(',')
    },    
  },
  methods: {
    async save() {
      try {
        if (this.$refs.form.validate()) {
          this.item.notasPagamento = this.notasPagamentoSelecionadas;
          if (this.item.id) {
            await update(this.item.id, this.item);
          } else {
            await create(this.item);
          }
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Pagamento ${this.item.id ? 'atualizado' : 'criado'} com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'criar'} o Pagamento'}.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>
.custom-card-text {
  max-height: 400px;
  overflow-y: auto;
}
</style>